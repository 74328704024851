import React, { Component } from "react";

import { Redirect } from "react-router-dom";

//datatable npm
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

// showing progress bar npm
import { PropagateLoader } from "react-spinners";
import LoadingBar from "react-top-loading-bar";

import Quoteinfoform from "../PClient/Quoteform";

import axios from "axios";

import Swal from "sweetalert2";
import Axios from "axios";
import { Multiselect } from "react-widgets";

import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import ClientHeader from "./ClientHeader";
var jwt=require('jsonwebtoken');
var jwtkey= require('../config');
export const Clientpagerefresh = () => {
  window.location.reload(true);
};

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: "checkbox",

  // clickToSelect: true
};

const { ExportCSVButton } = CSVExport;

class ClientViewKolList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Header Lable i.e speciality subspeciality and procedure or condition
      specialityLable: "",
      subSpecialityLable: "",
      procedureLable: "",
      client_id: "",
      client_name: "",
      login_type:"",
      client_email: "",
      client_token:'',


      searchKey: "",
      searchResultCount: "",
      //
      RedirectEmployeeKolList: false,
      RedirectClientProject: false,
      redirectClientKolProfile: false,
      redirectClientViewBiography: false,
      redirectClientViewProfile: false,
      redirect: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      // CountryListData: [],
      Profile: [],
      
      // to set form values
      Speciality: "",

      SubSpecialityType: [],
      SubSpecialityTypeString: "",
      ProcedureType: [],
      ProcedureTypeString: "",
      Subspeciality: [],

      showProcedureFlag: true,
      showSubSpecialityFlag: true,

      // searchg modal
      SearchModal: false,
      PrilimaryModal: false,
      infoQuoteModal: false,

      messageModal:false,

      //Column state for Bootstrap Table
      //var headerSortingStyle = { backgroundColor: "#e3edf8" };

      columns: [
        // {
        //     dataField: 'kol_unique_id',
        //     text: 'ID',
        // },
        {
          dataField: "rank_local",
          text: "Ranking",
          sort: true,

          formatter: (cell, row) => {
            return <div class="ttdata1">{row.rank_local}</div>;
          },

          headerStyle: (colum, colIndex) => {
            return {
              width: "3%",
              borderColor: "#02365F",
              textAlign: "left",
              fontSize: "13px",
              borderLeft: "solid",
              borderLeftColor: "#fff",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          //formatter: this.RankLocalFormater,
          // //classNames:'text-center',
          // class:'text-center',
          // cellStyle: {color: 'red', 'background-color': 'green'},
        },

        // {
        //   dataField:'kol_unique_id',
        //   text: 'KOL ID',
        //   formatter: (cell, row) => {
        //     return(
        //     <div class="ttdata1">

        //         {row.kol_unique_id}

        //       </div>
        //     )

        //    },
        //   sort: true,

        //   headerStyle: (colum, colIndex) => {
        //      return {width: '6%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        //   },

        //},

        {
          dataField: "profile_photo",
          text: "Image",
          formatter: this.PfrofileImageFormater,
          headerStyle: (colum, colIndex) => {
            return {
              width: "8%",
              borderColor: "#02365F",
              textAlign: "center",
              fontSize: "13px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
        },
        {
          dataField: "doctor_full_name",
          text: "KOL Name",
          formatter: this.linkToDetails,
          // sort: true,
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onProjectNameFilter = onFilter;
            return null;
          },
          headerStyle: (colum, colIndex) => {
            return {
              width: "12%",
              borderColor: "#02365F",
              textAlign: "left",
              fontSize: "13px",
              fontWeight: "bold",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
        },
        // {
        //   dataField: "speciality_type",
        //   text: "Specialty",

        //   filter: textFilter(),
        //   filterRenderer: (onFilter, column) => {
        //     this.onSpecityFilter = onFilter;
        //     return null;
        //   },

        //   formatter: (cell, row) => {
        //     return <div class="ttdata1">{row.speciality_type}</div>;
        //   },

        //   headerStyle: (colum, colIndex) => {
        //     return {
        //       width: "10%",
        //       borderColor: "#02365F",
        //       textAlign: "center",
        //       fontSize: "13px",
        //       borderTop: "solid",
        //       borderTopColor: "#fff",
        //     };
        //   },
        // },

        // {
        //   dataField: "procedure_type",
        //   text: "Procedure Type",
        //   // textAlign:'left',
        //   formatter: this.procedureformate,
        //   // formatter: (cell, row) => {
        //   //   return(
        //   //   <div class="ttdata1">
        //   //       {row.procedure_type}

        //   //   </div>
        //   //   )

        //   //  },
        //   headerStyle: (colum, colIndex) => {
        //     return {
        //       width: "10%",
        //       borderColor: "#02365F",
        //       textAlign: "center",
        //       fontSize: "13px",
        //       borderTop: "solid",
        //       borderTopColor: "#fff",
        //     };
        //   },
        //   // hidden: true,
        // },
        // {
        //   dataField: "expertise",
        //   text: "Expertise",
        //   // hidden: true,
        //   formatter: (cell, row) => {
        //     return <div class="ttdata1">{row.expertise}</div>;
        //   },
        //   filter: textFilter(),
        //   filterRenderer: (onFilter, column) => {
        //     this.onexpertiseFilter = onFilter;
        //     return null;
        //   },

        //   headerStyle: (colum, colIndex) => {
        //     return {
        //       width: "8%",
        //       borderColor: "#02365F",
        //       textAlign: "center",
        //       fontSize: "13px",
        //       borderTop: "solid",
        //       borderTopColor: "#fff",
        //     };
        //   },
        // },
        {
          dataField: "medical_schools",
          text: "Institution",
          formatter: (cell, row) => {
            console.log(cell)
            return <div class="ttdata1">{row.medical_schools ? row.medical_schools : "NA"}</div>;
          },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onInstitutionFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return {
              width: "8%",
              borderColor: "#02365F",
              textAlign: "center",
              fontSize: "13px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          // hidden: true,
        },
        {
          dataField: "country_name",
          text: "Country",
          formatter: (cell, row) => {
            return <div class="ttdata1">{row.country_name ? row.country_name : "NA"}</div>;
          },
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onCountryFilter = onFilter;
            return null;
          },
          headerStyle: (colum, colIndex) => {
            return {
              width: "8%",
              borderColor: "#02365F",
              textAlign: "center",
              fontSize: "13px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          //hidden: true,
        },
        // {
        //   dataField: "aggregate_score",
        //   text: "Rating",
        //   formatter: this.rating,
        //   headerStyle: (colum, colIndex) => {
        //     return {
        //       width: "8%",
        //       borderColor: "#02365F",
        //       textAlign: "center",
        //       fontSize: "13px",
        //       borderTop: "solid",
        //       borderTopColor: "#fff",
        //     };
        //   },
        // },

        // {
        //   dataField: 'qualification',
        //   text: 'Degree',
        //   csvExport: false
        // },
        // {
        //   dataField: "medical_schools",
        //   text: "Institution",
        //   // align: 'center',
        //   // headerAlign: 'center'
        // },
        // {
        //   dataField: "state_name",
        //   text: "State",
        //   csvExport: false
        // },
        // {
        //   dataField: "country_name",
        //   text: "Country",
        // },
        // {
        //   dataField: "procedure_potential",
        //   text: "Procedure Potential",
        //   csvExport: false
        // },
        // {
        //   dataField: "profile_photo",
        //   text: "Profile Photo",
        //  // hidden: true,
        // },

        {
          dataField: "",
          text: "Download",
          formatter: this.linkFollow,
          //formatter: this.linkToBio,
          csvExport: false,
          headerStyle: (colum, colIndex) => {
            return {
              width: "2%",
              borderColor: "#02365F",
              textAlign: "center",
              borderTop: "solid",
              borderTopColor: "#fff",
              borderRight: "solid",
              borderRightColor: "#fff",
            };
          },
        },
        {
          dataField: "last_engaged_on",
          text: "Last Engaged On",
          formatter: (cell, row) => {
            return <div class="ttdata1">{'NA'}</div>;
          },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            // this.onlast = onFilter;
            return null;
          },
          headerStyle: (colum, colIndex) => {
            return {
              width: "8%",
              borderColor: "#02365F",
              textAlign: "center",
              fontSize: "13px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          //hidden: true,
        },
      ],
    }; // End of states
  } // End of constructor

  componentDidMount = async () => {


    
 let getSession_wtoken = await sessionStorage.getItem('wtoken')
 console.log('tok', getSession_wtoken);

 jwt.verify(getSession_wtoken, jwtkey.jwt.key, async (err, response) => {
   if (!err && (response.login_type =="client" || response.login_type =="client_demo"))
     {

            //alert(sessionStorage.getItem('login_type'));
            let getSession_Client_token = await sessionStorage.getItem('token')
            //alert(getSession_Client_token);
            if (getSession_Client_token !== null) {
              await this.setState({
                client_token: getSession_Client_token,
              })
            }
            else
            {
              this.setState({ redirect: true });
            }

            let getSession_login_type = await sessionStorage.getItem("login_type");
            if (getSession_login_type !== null) {
              await this.setState({
                login_type: getSession_login_type,
              });
            }


            let getSession_Client_Email = await sessionStorage.getItem("email");
            if (getSession_Client_Email !== null) {
              await this.setState({
                client_email: getSession_Client_Email,
              });
            }

            let getSession_Client_ID = await sessionStorage.getItem("id");
            if (getSession_Client_ID !== null) {
              await this.setState({
                client_id: getSession_Client_ID,
              });
            }

            let getSession_Client_Name = await sessionStorage.getItem("name");
            if (getSession_Client_Name !== null) {
              await this.setState({
                client_name: getSession_Client_Name,
              });
            }

            console.log("getSession_Client_Email ====", getSession_Client_Email);
            console.log("getSession_Client_ID ====", getSession_Client_ID);
            console.log("getSession_Client_Name ====", getSession_Client_Name);

            let getSession_Project_id = await sessionStorage.getItem("project_id");
            if (getSession_Project_id !== null )  {
              console.log('getSession_Project_id',getSession_Project_id);
              await this.setState({
                project_id: getSession_Project_id,
              });
            }
            else  if (response.login_type =="client" ) {
              this.setState({ redirect: true });
            }

            console.log("getSession_Project_id ====", getSession_Project_id);

            const specialityLable_=localStorage.getItem("specialityLable");
            if(specialityLable_ !=null)
            {
              this.setState({specialityLable:specialityLable_})
            }

            const subSpecialityLable_=localStorage.getItem("subSpecialityLable");
            if(subSpecialityLable_ !=null)
            {
              this.setState({subSpecialityLable:subSpecialityLable_})
            }

            const ProcedureLable_=localStorage.getItem("procedureLable");
            if(ProcedureLable_ !=null)
            {
              this.setState({procedureLable:ProcedureLable_})
            }

            
            console.log('formdata',this.state.procedureLable);

            const GetSearchedData=JSON.parse(localStorage.getItem("SearchedProfileData"));
            console.log("Session_val",GetSearchedData);
            //      alert( JSON.parse(GetSearchedData).length);
            if(GetSearchedData !=null)
            {
              this.setState({
                Profile: GetSearchedData,
              });
          
            }
            else
            {

            //  let formData1=new FormData();
              
            // searchResultCount = https://www.cetas.medtech-gurus.com/Cetas/service/Client/get_total_searched_kol_no
            const searchResultUrl = global.websiteUrl + "/service/Client/get_total_searched_kol_no"
              , formDataSearchResult = new FormData()
              , configSearchResult = {}
              formDataSearchResult.append('token_id',getSession_Client_token);
            
            axios
              .post(searchResultUrl, formDataSearchResult, configSearchResult)
              .then((response) => {
                console.log("response of Search Result Count", response);
                this.setState({ searchResultCount: response.data.no_of_kol })
                if (response.data.status == false) {
                  console.log("status false..!!");
                } else if (response.data.status == true) {
                  console.log("status true..!!");
                  this.state.searchResultCount = response.data.no_of_kol;
                }
                // console.log("Length of Kol List ",this.state.Profile.length);
              })
              .catch((error) => {
                console.log(error);
              });
            // service / Client / get_demoKOLList
            const loginType = sessionStorage.getItem('login_type')
            const url = loginType == "client_demo" ? global.websiteUrl + "/service/Client/get_demoKOLList" : global.websiteUrl + "/service/Client/get_ClientProjectKOLList";
            console.log({ url })
            let formData = new FormData();
            if (loginType !== "client_demo") {
              formData.append("project_id", this.state.project_id);
              formData.append('token_id', getSession_Client_token);

            } else {
              formData.append("project_id", "");
              formData.append("specialty_id", "");
              formData.append("country_id", "");
              formData.append("sub_specialty_id", "");
              formData.append("procedure_type_id", "");
              formData.append("condition_type_id", "");
              formData.append('token_id',this.state.client_token);
              formData.append("project_id", "");
              
            }
            this.setState({
              ShowBar: true,
              progressBar: true,
            });

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            };

            axios
              .post(url, formData, config)
              .then((response) => {
                console.log("response of API", response);
                this.Profile = loginType == "client_demo" ? response.data.KOL_Details : response.data.KOL_Project_Details;
                // console.log(this.Profile.length);
                console.log(
                  "Profile===",
                  JSON.stringify(response.data.KOL_Project_Details)
                );

                if (response.data.status == false) {
                  console.log("status false..!!");
                } else if (response.data.status == true) {
                  console.log("status true..!!");
                  let list_here = loginType == "client_demo" ? response.data.KOL_Details : response.data.KOL_Project_Details;
                  list_here.map(async (data, index) => {
                    // console.log("item getSession_kolInfo", data);
                    data.rank_local = index + 1;
                  });
                  console.log("list_here", list_here);

                  this.setState({
                    Profile: list_here,
                      ShowBar: false,
                      progressBar: false,
                    
                  });
                }
                // console.log("Length of Kol List ",this.state.Profile.length);
              })
              .catch((error) => {
                console.log(error);
              });
    }

}
else {

  Swal.fire({
    icon: 'error',
    title: jwtkey.jwt.session_expired_msg_title,
    text: jwtkey.jwt.wrong_url_msg,
    // showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#d33',
    confirmButtonText: 'OK'
  })
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('wtoken');
  sessionStorage.removeItem('id');
  sessionStorage.removeItem('name');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('login_type');
  
  sessionStorage.removeItem("speciality_id");
  this.setState({ redirect: true })
}
})

  };

  

  //Funtion to apply links to project names style={{backgroundColour:'#A51E22',borderColor:'#A51E22'}}
  rating = (cell, row) => {
    var kol_id = row.kol_unique_id;

    return (
      <div>
        {row.aggregate_score == "NA" || row.aggregate_score == "" ? (
          <div class="hexagon">
            <span class="hexL">
              <a
                href="#"
                onClick={this.toggleinfoQuoteModal.bind(this)}
                className="lock_anchor"
              ></a>{" "}
            </span>
          </div>
        ) : (
          <div>
            <div class="hexagon">
              <span class="hexL">{row.aggregate_score}</span>
            </div>
          </div>
        )}
      </div>
      // <div class="hexagon" >
      //   <span class="hexL" >{row.aggregate_score}</span>

      // </div>
    );
  };

  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    var raking = row.aggregate_score;

    return (
      <div>
        <a
          style={{
            color: "#069",
            fontSize: "12px",
            fontWeight: "bold",
            textAlign: "left",
            textDecoration: "underline",
            cursor: "pointer",
            position: "relative",
            marginTop: "20px",
            display: "inline-block",
          }}
          onClick={this.profileInfoNav.bind(this, kol_id, raking)}
        >
          {" "}
          {row.doctor_full_name}{" "}
        </a>
      </div>
    );
  };

  //Funtion to apply links to Biography
  linkToBio = (cell, row) => {
    var kol_id = row.kol_unique_id;

    return (
      <div>
        <a
          style={{
            color: "#069",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={this.bioInfoNav.bind(this, kol_id)}
        >
          {" "}
          {row.doctor_full_name}{" "}
        </a>
      </div>
    );
  };

  RankLocalFormater = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a
          style={{
            color: "#000",
          }}
        >
          {row.rank_local}
        </a>
      </div>
    );
  };

  procedureformate = (cell, row) => {
    var procedure = row.procedure_type;
    let pro = procedure.split(",");
    var finalprocedure = pro[0];

    if (pro.length > 1) {
      finalprocedure += ", " + pro[1] + " and more…";
      //alert(pro[1]);
    }
    return <div class="ttdata1">{finalprocedure}</div>;
  };

  PfrofileImageFormater = (cell, row) => {
    var imgsource = ""
      , host = window.location.origin
      , imagePath = `${host}/ProfileImage/`
    // alert(row.profile_photo);
    console.log(window.location.origin)
    if (row.profile_photo !== null) {
      imgsource = imagePath + row.profile_photo;
    } else {
      imgsource = "require('../Assets/Images/default-user.jpg')";
      // imgsource="{require('../Assets/Images/cetas-logo.png')}";
    }

    return (
      <div>
        <td>
          {
            <img src={imgsource} class="Client_profile_pic" />
          }
        </td>
      </div>
    );
  };

  profileInfoNav(kol_id, raking) {
   
    sessionStorage.setItem("kol_id", JSON.stringify(kol_id));
    console.log("Navigation of clientKolProfile", kol_id);
    this.setState({
      redirectClientKolProfile: true,
    });
  }

  bioViewNav(kol_id, raking) {
    // if(raking !=='NA')
    //   {

    sessionStorage.setItem("kol_id", JSON.stringify(kol_id));
    console.log("Navigation of ClientViewBio", kol_id);
    this.setState({
      //redirectClientKolProfile: true,
      redirectClientViewBiography: true,
    });
    //  }
    //   else
    //   {
    //    this.setState({
    //       PrilimaryModal:true,
    //    })

    //   }
  }

  profileViewNav(kol_id, raking) {
    // if(raking !=='NA')
    // {

    sessionStorage.setItem("kol_id", JSON.stringify(kol_id));
    console.log("Navigation of ClientViewProfile", kol_id);
    this.setState({
      //redirectClientKolProfile: true,
      redirectClientViewProfile: true,
    });

    // }
    // else
    // {
    //  this.setState({
    //     PrilimaryModal:true,
    //  })

    // }
  }

  CVViewNav(kol_id, raking, resumeurl) {
    // if(raking !=='NA')
    // {
    window.open(resumeurl, "_blank");
     }

  

  // Funtion for displaying 'bio', 'Profile' & 'CV' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    var kol_id = row.kol_unique_id;
    var rating = row.aggregate_score;
    return (
      <div>
        <td class="butns1" style={{ display: "inline-flex" }}>
          {
            <a onClick={this.bioViewNav.bind(this, kol_id, rating)}>
              <button
                class="btn1 btn-danger btn-block btn-sm btn-labeled"
                style={{ width: "100px", margin: "0 10px" }}
              >
                Bio
              </button>
            </a>
          }
          {
            <a onClick={this.profileViewNav.bind(this, kol_id, rating)}>
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled" style={{ margin: "0 10px" }}>
                Profile
              </button>
            </a>
          }
          {/* {row.resume !== null ? (
            // <a href={ row.resume } target="_blank">
            <a onClick={this.CVViewNav.bind(this, kol_id, rating, row.resume)}>
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled">
                CV
              </button>
            </a>
          ) : (
            <div></div>
          )} */}
        </td>
      </div>
    );
  };
  searchInputChange = (event) => {
    const { value } = event.target
      , { searchKey } = this.state
    console.log(typeof this.state.searchKey)
    console.log({ value })
    switch (searchKey) {
      case "1":
        console.log('KOL Name')
        this.onProjectNameFilter(value)
        break
      case "2":
        console.log('Instituation Name')
        this.onInstitutionFilter(value);
        break
      case "3":
        console.log('Country Name')
        this.onCountryFilter(value);
        break
      default:
    }

  }
  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onProjectNameFilter(value);
  };

  onSpecilityChange = (event) => {
    const { value } = event.target;
    this.onSpecityFilter(value);
  };

  onInstitutionChange = (event) => {
    const { value } = event.target;
    this.onInstitutionFilter(value);
  };

  onexpertiseChange = (event) => {
    const { value } = event.target;
    this.onexpertiseFilter(value);
  };

  onCountryChange = (event) => {
    const { value } = event.target;
    this.onCountryFilter(value);
  };

  BackToClientProject() {
    this.setState({ RedirectClientProject: true });
  }

  logout = () => {
    console.log("in logout");
    localStorage.removeItem("SearchedProfileData");
  
    this.setState({ redirect: true });
    sessionStorage.removeItem("client_id");
    sessionStorage.removeItem("client_name");
    sessionStorage.removeItem("email_id");
  };

  // search modal functions
  toggleSearchModal = () => {
    this.setState({
      SearchModal: !this.state.SearchModal,
      ProcedureType: [],
      ProcedureTypeString: "",
      SubSpecialityType: [],
      SubSpecialityTypeString: "",
    });
  };

  // search modal functions
  togglePrilimaryModal = () => {
    this.setState({
      PrilimaryModal: !this.state.PrilimaryModal,
    });
  };

  toggleinfoQuoteModal = () => {
    this.setState({
       messageModal:false,
      infoQuoteModal: !this.state.infoQuoteModal,
    });
  };

  


  togglemessageModal = () => {
    this.setState({
      messageModal: !this.state.messageModal,
    });
  };
  
  render() {
    if (this.state.RedirectClientProject) {
      return (
        <Redirect
          to={{
            pathname: "/My Dashboard",
          }}
        />
      );
    }

    if (this.state.redirectClientKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/Profile View",
          }}
        />
      );
    }

    if (this.state.redirectClientViewBiography) {
      return (
        <Redirect
          to={{
            pathname: "/Bio Export",
          }}
        />
      );
    }

    if (this.state.redirectClientViewProfile) {
      return (
        <Redirect
          to={{
            pathname: "/Profile Export",
          }}
        />
      );
    }

    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    // Speciality Type options
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) => (
      // <option key={speciality_type.id} value={speciality_type.id}>
      //   {speciality_type.speciality_type}</option>
      <option key={speciality_type} value={speciality_type.id}>
        {speciality_type.speciality_type}
      </option>
    ));

    
    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick}>
          <a class="page-link" href="#">
            {page}
          </a>
        </li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: "Previous",
      nextPageText: "Next",
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "200",
          value: 200,
        },
        {
          text: "All",
          value: this.state.Profile.length,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };

    // const MyExportCSV = (props) => {
    //   const handleClick = () => {
    //     props.onExport();
    //   };
    //   return (
    //     <div>
    //       <button class="cetas-button" onClick={handleClick}>Export</button>
    //     </div>
    //   );
    // };
    const submitKolForm = (searchType = 'Clinical', data = {}, closeModel) => {
      console.log(data)
      const country = data?.country ? data.country.map(c => c.value).toString() : ""
        , sub_specialty_id = data?.subSpeciality ? data.subSpeciality.map(ssp => ssp.value).toString() : ""
        , specialty_id = data?.speciality ? data.speciality.map(sp => sp.value).toString() : ""
        , procedure = data.procedureType == "Procedure" && data?.procedure ? data.procedure.map(p => p.value).toString() : ""
        , condition = data.procedureType == "Condition" && data?.procedure ? data.procedure.map(p => p.value).toString() : ""
        , procedure1 = data.procedureType == "Procedure" && data?.procedure ? data.procedure.map(p => p.label).toString() : ""
        , condition1 = data.procedureType == "Condition" && data?.procedure ? data.procedure.map(p => p.label).toString() : ""
    
        console.log({ country, sub_specialty_id, specialty_id, procedure, condition })
      
      this.setState({
        ShowBar: true,
        specialityLable: data?.speciality ? data.speciality.map(sp => sp.label).toString() : "",
        subSpecialityLable: data?.subSpeciality ? data.subSpeciality.map(ssp => ssp.label).toString() : "",
        procedureLable: procedure1 != "" ? procedure1 : condition1,
       });

        
    //  console.log('formdata',procedure1,condition1);

      localStorage.setItem("specialityLable",data?.speciality ? data.speciality.map(sp => sp.label).toString() : "",);
      localStorage.setItem("subSpecialityLable",data?.subSpeciality ? data.subSpeciality.map(ssp => ssp.label).toString() : "",);
      localStorage.setItem("procedureLable",procedure1 != "" ? procedure1 : condition1);
     
      if (searchType = 'Clinical') {
      //  alert(this.state.login_type);
      
        const url1 =
          global.websiteUrl + "/service/Client/get_demoKOLList";
        let formData1 = new FormData();
        formData1.append("project_id", this.state.project_id);            
        formData1.append("specialty_id", specialty_id);
        formData1.append("country_id", country);
        formData1.append("sub_specialty_id", sub_specialty_id);
        formData1.append("procedure_type_id", procedure);
        formData1.append("condition_type_id", condition);
        formData1.append('token_id', this.state.client_token);


        const config1 = {
          //   headers: { 'content-type': 'multipart/form-data' }
        };

        axios.post(url1, formData1, config1).then((response) => {
          // console.log(response);
          var lgdata = response.data;
          // console.log("lgdata", JSON.stringify(lgdata));
          if (response.data.status == true) {
          
            localStorage.setItem("SearchedProfileData",JSON.stringify(response.data.KOL_Details));
            
             this.setState({
              Profile: response.data.KOL_Details,
              speciality_type_type: response.data.KOL_Details[0].speciality_type,            
              ShowBar: false,
            });

            closeModel()
          } else {
            this.setState({
              Profile: [],
              speciality_type_type: "",
              ShowBar: false,
              });

            if( this.state.login_type =="client" )
              {
                this.setState({
                 
                  messageModal:true
                  
                });
              }

            closeModel()
          }
        }, err => {
          this.setState({
            Profile: [],
            speciality_type_type: "",
            ShowBar: false
          });
          closeModel()
        });
      } else {
        console.log('Non Clinical Speciality')
      }
    }
    return (
      <div style={{ backgroundColor: "#F9F9F9", paddingBottom: "35px" }}>
        {this.state.ShowBar ? (
          <Modal isOpen={true} centered>
            <PropagateLoader color={"#A90007"} size="30px" loading="true" />
          </Modal>
        ) : null}
        {this.state.progressBar ? (
          <div style={{ position: "fixed", top: "0" }}>
            <LoadingBar
              progress="100"
              height={3}
              color="#A90007"
              transitionTime={4000}
              loaderSpeed={3000}
            />
          </div>
        ) : null}
        <div>
          <ClientHeader onSubmitHandler={submitKolForm} />

          {/* 
            <div class="container"  >
                            <div class="profile-backoptiopn">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                              <p><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p>
                    
                 
                            </div>
                        </div>
                      </div>
       */}

          <div
            class="container-fluid"
            id="mainClientDashboard"
            style={{ width: "98%" }}
          >
            {/* <div className='clientheadingbox' style={{width:'100%' }} > */}
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="row breadcum">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <h4>
                    <b>Opinion Leaders</b>
                  </h4>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <p>
                    {sessionStorage.getItem('login_type') == 'client_demo' ?
                      <div>
                      </div> : <>
                        <a href="#" onClick={this.BackToClientProject.bind(this)}>
                          Dashboard
                        </a>{" "}
                        <b>
                          <span class="btn-label">
                            <i class="fa fa-caret-right"></i>{" "}
                          </span>
                        </b>
                        {" "}
                        Opinion Leaders
                      </>
                    }

                  </p>
                </div>
              </div>
            </div>

            {/* </div> */}

            <div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="card">
                  <div class="card-body">
                    <div className="gridtable">
                      <h4
                        style={{
                          height: "50px",
                          fontSize: "20px",
                          fontWeight: "bolder",
                        }}
                      >
                        {/* {alert(this.state.procedureLable)} */}
                        {sessionStorage.getItem("login_type") == 'client_demo' ?
                        // `${this.state.specialityLable} ${this.state.subSpecialityLable ? `- ${this.state.subSpecialityLable}` : ""} `
                        `${this.state.procedureLable ? ` ${this.state.subSpecialityLable ? `${this.state.subSpecialityLable} - ${this.state.procedureLable}` : `${this.state.specialityLable} - ${this.state.procedureLable}`} ` : `${this.state.specialityLable} ${this.state.subSpecialityLable ? `- ${this.state.subSpecialityLable}` : ""} ` } `
                          : `${this.state.project_id} -` + " " + `${sessionStorage.getItem("p_name")}` + " "}

                        < div style={{ float: "right", fontSize: "15px", display: "flex" }}>
                          <Input
                            type="select"
                            placeholder="Search by.."
                            id="searchkol"
                            style={{ marginLeft: "1%", width: "200px" }}
                            onChange={(e) => {
                              this.setState({ searchKey: e.target.value })
                            }}
                          >
                            <option value={0} >Search by..</option>
                            <option value={1} >KOL Name</option>
                            <option value={2} >Institution</option>
                            <option value={3} >Country</option>
                          </Input>
                          <input placeholder="Enter the exact value" style={{ marginLeft: "1%", fontWeight: "normal", height: "fit-content" }} onChange={this.searchInputChange} />
                          {/* <button
                            class="btn1 btn-danger btn-block btn-sm btn-labeled"
                            style={{ width: "100px", marginLeft: "1%", height: "35px" }}
                            disabled
                          // onClick={this.search}
                          ><span class="fa fa-search">  Search</span></button> */}
                        </div>
                        {/* <div class="preclient">
                          <div className='searchbox' style={{}}>
                            <Input type="select" id="SpecilitySelect"
                              //value={this.state.speciality_name}
                              onChange={(e) => {
                                this.setState({ speciality_name: e.target.value })
                              }}
                            >
                              <option value="0" >Search Speciality...</option>
                              {OptionsSpeciality}

                            </Input>
                          </div>
                        </div> */}
                      </h4>

                      {/* <div class="row" style={{ marginBottom: "30px" }}>
                        <div class="col-md-2" style={{ marginLeft: "-130px" }}>
                          <p class="searching">
                            <div className="employee-actions">
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30">
                                  <div class="input-group">
                                     <span
                                      class="btn-label"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <i class="fa fa-search"></i>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by KOL Name"
                                      onChange={(e) => {
                                        this.onProjectNameChange(e);
                                      }}
                                    /> 
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </p>
                        </div>

                        {/* <div class="col-lg-2 col-md-2 col-sm-12 col-12"   >
                          <p class="searching" >
                              <div className="employee-actions" >
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span>  
                                     <input type="text" 
                                     className="form-control"  
                                     //value={this.state.Speciality}
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by Specialty"
                                      onChange={(e) => { this.onSpecilityChange(e) }} 
                                      />

                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>

                        </div> */}

                      {/* <div class="col-lg-2 col-md-2 col-sm-12 col-12" >
                          <p class="searching" >
                              <div className="employee-actions" >
                              <div className="table-infobox">
                                <div class="filter-box float-left mb-30" >
                                  <div class="input-group">                            
                                    {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span>
                                    <input type="text" className="form-control"  
                                      id="inlineFormInputGroupUsername"
                                      placeholder="Search by expertise"
                                      onChange={(e) => { this.onexpertiseChange(e) }} />
                                      
                                  </div>
                                </div>                          
                              </div>
                            </div>
                          </p>                          
                        </div>  

                      <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                        <p class="searching">
                          <div className="employee-actions">
                            <div className="table-infobox">
                              <div class="filter-box float-left mb-30">
                                <div class="input-group">
                                  {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inlineFormInputGroupUsername"
                                    placeholder="Search by Institution"
                                    onChange={(e) => {
                                      this.onInstitutionChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>

                      <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                        <p class="searching">
                          <div className="employee-actions">
                            <div className="table-infobox">
                              <div class="filter-box float-left mb-30">
                                <div class="input-group">
                                  {/* <span class="btn-label" style={{marginTop:'10px'}}>
                                    <i class="fa fa-search"></i>  
                                    </span> 
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inlineFormInputGroupUsername"
                                    placeholder="Search by Country"
                                    onChange={(e) => {
                                      this.onCountryChange(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div> */}




                      {sessionStorage.getItem("login_type") == 'client_demo' && localStorage.getItem('specialityLable') ==null ?
                                            <div>
                                            </div> : <>
                                            <span className="text text-muted" style={{ fontWeight: "bold", fontSize: "14px" }}>{this.state.Profile.length} Results...</span>
                  
                                            
                                            </>
                      }

                      <table
                        class="table table-hover"
                        style={{ width: "100%", maxWidth: "100%" }}
                      >

                        <ToolkitProvider
                          keyField="kol_unique_id"
                          data={this.state.Profile}
                          columns={this.state.columns}
                          search={this.customMatchFunc}
                        // //exportCSV
                        // exportCSV={{
                        //   fileName: 'cetas.csv',
                        //   noAutoBOM: false,
                        //   onlyExportSelection: true,
                        //   exportAll: true
                        // }}
                        >
                          {(props) => {
                            console.log(props)
                            return (< div >
                              <BootstrapTable
                                {...props.baseProps}
                                defaultSortDirection="asc"
                              
                                filter={filterFactory()}
                                //selectRow={selectRow}
                                pagination={paginationFactory(options)}
                                // striped
                                hover
                                condensed
                                tabIndexCell
                                rowStyle={{
                                  backgroundColor: "White",
                                  borderLeft: "solid",
                                  borderLeftColor: "#fff",
                                  borderBottom: "solid",
                                  borderBottomColor: "#EDE9E8",
                                  borderRight: "solid",
                                  borderRightColor: "#fff",
                                }}
                              />

                              <br />
                              {/* <MyExportCSV {...props.csvProps} /> */}
                            </div>)
                          }}
                        </ToolkitProvider>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          {/* messagepopup */}

          <Modal isOpen={this.state.messageModal} centered >
             <ModalHeader
              toggle={this.togglemessageModal.bind(this)} 

              cssModule={{
                "modal-title": "w-100 text-center",
                "border-bottom": "0px",
              }}
              style={{ borderBottom: "0px" }}
            >
              <h3
                style={{ justifyContent: "center", marginTop: "20px" }}
                id="login-tit"
              >
                {/* Get a quote */}
              </h3>
            </ModalHeader>
            <ModalBody style={{ padding: "0px 30px 0px 30px"}}>
              <h4 style={{"line-height":'inherit',textAlign:"justify" }}>Your current subscription doesn't have access to this type of KOLs. Would you like to add this type of KOLs to your subscription? </h4>
              {/* <Quoteinfoform></Quoteinfoform> */}
            </ModalBody>
            <ModalFooter style={{ borderTop: '0px' , paddingBottom:'40px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '90px' }}
                                                onClick={this.togglemessageModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', height: '40px', width: '90px'}}
                                                onClick={this.toggleinfoQuoteModal.bind(this)}>
                                                Yes
                                            </button> 

                                        </ModalFooter>
          </Modal>




          {/* infoQuoteModal */}

          <Modal isOpen={this.state.infoQuoteModal} centered>
            {/* toggle={this.toggleinfoQuoteModal.bind(this)} */}
            <ModalHeader
              toggle={this.toggleinfoQuoteModal.bind(this)}
              cssModule={{
                "modal-title": "w-100 text-center",
                "border-bottom": "0px",
              }}
              style={{ borderBottom: "0px" }}
            >
              <h3
                style={{ justifyContent: "center", marginTop: "20px" }}
                id="login-tit"
              >
                Get a quote
              </h3>
            </ModalHeader>
            <ModalBody style={{ padding: "0px 60px 0px 60px" }}>
              <Quoteinfoform></Quoteinfoform>
            </ModalBody>
            {/* <ModalFooter style={{ borderTop: '0px' , paddingBottom:'40px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '90px' }}
                                                onClick={this.toggleinfoQuoteModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', height: '40px', width: '90px'}}
                                                onClick={this.SaveQuoteinfo.bind(this)}>
                                                Next
                                            </button>

                                        </ModalFooter> */}
          </Modal>

          {/* PrilimaryModal */}

          <Modal
            isOpen={this.state.PrilimaryModal}
            toggle={this.togglePrilimaryModal.bind(this)}
            centered
          >
            <ModalHeader
              toggle={this.togglePrilimaryModal.bind(this)}
              cssModule={{
                "modal-title": "w-200 text-center",
                "border-bottom": "0px",
              }}
              style={{ borderBottom: "0px" }}
            >
              {/* <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Search KOL</h3> */}
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <div className="center">
                  <h3>
                    {" "}
                    <u>Contact</u>
                  </h3>
                </div>
                <div className="center">
                  <h4>
                    <a style={{ color: "blue" }}> xyz@cetas-healthcare.com</a>
                  </h4>
                </div>

                {/* <div className="center">
                  <h4></h4>
                </div> */}
              </FormGroup>
            </ModalBody>
          </Modal>


          {/* 
          <footer class="footer_not">

                        <div class="cetas-footer">
                            <div class="container" >
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
            </footer> */}
        </div >
      </div >
    );
  }
}

export default ClientViewKolList;
